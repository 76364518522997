<template>
  <el-icon :size="20">
    <component :is='icon'></component>
    <!-- <edit /> -->

  </el-icon>
  <span v-if="title">{{ title }}</span>
</template>

<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.el-submenu [class^="el-icon-"] {
  text-align: left;
}

.el-icon {
  margin: 0px 5px;
}
</style>

<style>
#app .hideSidebar .el-sub-menu .el-sub-menu__icon-arrow {
  display: none;
}

#app .hideSidebar .el-sub-menu .el-sub-menu__title {
  padding: 0px;
}

</style>
