/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const salesRoutes = {
  path: '/sales',
  component: Layout,
  name: 'Sales',
  alwaysShow: true,
  meta: {
    title: 'Sales',
    icon: 'DataLine',
    //  permissions: ['view menu sales'],
  },
  children: [
    /** Settings managements */

    {
      path: 'list',
      component: () => import('../views/sale/List'),
      name: 'saleList',
      meta: {
        title: 'Sale List',
        icon: 'Memo',
        //  permissions: ['view sale list'],
      },
    },
    {
      path: 'create',
      component: () => import('../views/sale/Create'),
      name: 'createList',
      meta: { title: 'Create Sale', icon: 'Memo' },
    },
    {
      path: 'view/:id(\\d+)',
      component: () => import('../views/sale/View'),
      name: 'ViewSale',
      meta: {
        title: 'View Sale',
        noCache: true,
      },
      hidden: true,
    },
    {
      path: 'register/list',
      component: () => import('../views/sale/RegisterList'),
      name: 'RegisterList',
      meta: {
        title: 'Sale Register List',
        icon: 'Memo',
        //  permissions: ['view sale list'],
      },
    },
    {
      path: 'sale-report',
      component: () => import('../views/sale/sale-report'),
      name: 'saleReport',
      meta: {
        title: 'Sale Report',
        icon: 'list',
        //  permissions: ['view sale list'],
      },
      hidden: true,
    },
  ],
};

export default salesRoutes;
