import { ElMessage } from 'element-plus';
import axios from 'axios';
import bcrypt from 'bcryptjs';

const state = {
  id: null,
  entry_pin: '',
  hash_pin: '',
};
const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },

  SET_ENTRY_PIN: (state, entry_pin) => {
    state.entry_pin = entry_pin;
  },
  SET_HASH_PIN: (state, hash_pin) => {
    state.hash_pin = hash_pin;
  },

};
const actions = {
  setPin({ commit, state }){
    axios.get('/api/entry-pin/get-pin').then((response) => {
      if (response.status === 200){
        commit('SET_HASH_PIN', response.data.hash_pin);
      } else {
        ElMessage({
          type: 'error',
          message: response.message,
        });
        return false;
      }
    });
  },
  checkPin() {
    return new Promise((resolve, reject) => {
      var hash_input = prompt('please enter pin');
      // check PIN here
      bcrypt.compare(hash_input, state.hash_pin, function(err, result) {
        if (result === true){
          resolve(true);
        } else {
          ElMessage({
            message: 'Pin Does Not Matched',
            type: 'error',
            duration: 5 * 1000,
          });
        }
      });
    });
  },
};

export default {
  actions,
  state,
  mutations,
};

