const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  userId: state => state.user.id,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  phone: state => state.user.phone,
  country: state => state.user.country,
  state: state => state.user.state,
  district: state => state.user.district,
  city: state => state.user.city,
  zip_code: state => state.user.zip_code,
  street: state => state.user.street,
  image: state => state.user.image,
  user: state => state.user.user,
  introduction: state => state.user?.introduction,
  roles: state => state.user?.roles,
  permissions: state => state.user?.permissions,
  permissionRoutes: state => state.permission?.routes,
  addRoutes: state => state.permission?.addRoutes,
  authUserRole: state => state.permission?.authUserRole,
  company: state => state.supplier?.company,
  office_info: state => state.company?.office_info,
};
export default getters;
