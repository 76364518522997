import '@/styles/index.scss';
import 'element-plus/dist/index.css';
import 'core-js';
import { createApp } from 'vue';
import Cookies from 'js-cookie';
import ElementPlus from 'element-plus';
import App from './views/App.vue';
import store from './store';
import router from './router';
import i18n from './lang';
import '@/permission';
import { isLogged } from '@/utils/auth';
import helperPlugins from './filtersPlugin';
import SvgIcon from '@/components/SvgIcon/index.vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import printPlugin from '@/print';
// import VueQRCodeComponent from 'vue-qrcode-component';


const app = createApp(App);
app.component('svg-icon', SvgIcon);
app.use(helperPlugins);
app.use(router);

// app.component('qr-code', VueQRCodeComponent);
// app.use(printPlugin);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, {
  size: Cookies.get('size') || 'medium',
  i18n: (key, value) => i18n.t(key, value),
});

app.use(i18n);
app.use(store);
app.mount('#app');

const isUserLogged = isLogged();
if (isUserLogged) {
  store.dispatch('getOfficeInfo');
}

app.config.productionTip = false;
