<template>
  <div v-if="isFullscreen">
    <el-icon @click="click"><Rank /></el-icon>
  </div>

  <div v-else>
    <el-icon @click="click"><FullScreen /></el-icon>
  </div>
</template>

<script>
import screenfull from 'screenfull';

export default {
  name: 'Screenfull',
  data() {
    return {
      isFullscreen: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning',
        });
        return false;
      }
      screenfull.toggle();
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', () => {
          this.isFullscreen = screenfull.isFullscreen;
        });
      }
    },
  },
};
</script>

<style scoped>
.el-icon {
  font-size: 23px;
  margin-top: 13px;
}
</style>
