import Resource from '@/api/resource';
const officeInfoResource = new Resource('office-info');
const state = {
  name: '',
  company_name: '',
  designation: '',
  office_address: '',
  telephone: '',
  phone: '',
  website_url: '',
  email: '',
  office_info: {},
};

const mutations = {
  CHANGE_OFFICE_INFO: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  SET_OFFICE_INFO: (state, office_info) => {
    state.office_info = office_info;
  },
  SET_COMPANY_NAME: (state, company_name) => {
    state.company_name = company_name;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_DESIGNATION: (state, designation) => {
    state.designation = designation;
  },
  SET_OFFICE_ADDRESS: (state, office_address) => {
    state.office_address = office_address;
  },
  SET_TELEPHONE: (state, telephone) => {
    state.telephone = telephone;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_WEBSITE_URL: (state, website_url) => {
    state.website_url = website_url;
  },
};

const actions = {

  changeOfficeInfo({ commit }, data) {
    commit('CHANGE_OFFICE_INFO', data);
  },

  getOfficeInfo({ commit, state }){
    return new Promise((resolve, reject) => {
      officeInfoResource.get(1)
        .then(response => {
          const { data } = response;
          const { name, company_name, designation, email, office_address, telephone, phone, website_url } = data;
          commit('SET_NAME', name);
          commit('SET_DESIGNATION', designation);
          commit('SET_OFFICE_ADDRESS', office_address);
          commit('SET_TELEPHONE', telephone);
          commit('SET_COMPANY_NAME', company_name);
          commit('SET_PHONE', phone);
          commit('SET_EMAIL', email);
          commit('SET_WEBSITE_URL', website_url);
          commit('SET_OFFICE_INFO', data);
          resolve(data);
        });
    });
  },
  
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
};

