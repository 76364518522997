/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const settingsRoutes = {
  path: '/settings',
  component: Layout,
  redirect: '/settings',
  name: 'Settings',
  alwaysShow: true,
  meta: {
    title: 'settings',
    icon: 'Tools',
    permissions: ['view menu settings'],
  },
  children: [
    /** Settings managements */
    {
      path: 'pos-settings/update/1',
      component: () => import('@/views/pos-setting/Update'),
      name: 'PosSettingUpdate',
      meta: { title: 'Update Pos Setting', icon: 'edit' },
    },
    {
      path: 'office-info/update/1',
      component: () => import('@/views/office-info/Update'),
      name: 'OfficeInfoUpdate',
      meta: { title: 'Office Info Update', icon: 'edit', permissions: ['update entry pin'] },
    },
    {
      path: 'print-paper-size',
      component: () => import('@/views/print-paper-size/List'),
      name: 'PaperPrintList',
      meta: { title: 'Print Paper Size', icon: 'edit', permissions: ['update entry pin'] },
    },
    {
      path: 'entry-pin/update/1',
      component: () => import('@/views/entry-pin/Update'),
      name: 'entryPinUpdate',
      meta: { title: 'entryPinUpdate', icon: 'edit', permissions: ['update entry pin'] },
    },
    {
      path: 'milestone/list',
      component: () => import('@/views/milestone/List'),
      name: 'milestoneList',
      meta: { title: 'Milestone List', icon: 'Memo' },
    },
    {
      path: 'milestone/create',
      component: () => import('@/views/milestone/Create'),
      name: 'milestoneCreate',
      meta: { title: 'Milestone Create', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'milestone/view/:id(\\d+)',
      component: () => import('@/views/milestone/View'),
      name: 'viewMilestone',
      meta: { title: 'View Milestone', icon: 'edit', noCache: true },
      hidden: true,
    },
    {
      path: 'country/list',
      component: () => import('@/views/country/List'),
      name: 'countryList',
      meta: { title: 'countryList', icon: 'Memo', permissions: ['view country list'] },
    },
    {
      path: 'state/list',
      component: () => import('@/views/state/List'),
      name: 'stateList',
      meta: { title: 'stateList', icon: 'Memo', permissions: ['view state list'] },
    },
    {
      path: 'district/list',
      component: () => import('@/views/district/List'),
      name: 'districtList',
      meta: { title: 'districtList', icon: 'Memo', permissions: ['view district list'] },
    },
    {
      path: 'city/list',
      component: () => import('@/views/city/List'),
      name: 'cityList',
      meta: { title: 'cityList', icon: 'Memo', permissions: ['view city list'] },
    },
  ],
};

export default settingsRoutes;
