import defaultSettings from '@/settings';
const { showSettings, tagsView, fixedHeader, sidebarLogo, theme } = defaultSettings;
import { fetchSettings } from '@/api/settings';

const state = {
  theme: theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  inbound_prefix: '',
  outbound_prefix: '',
  inbound_sd: '',
  outbound_sd: '',
  company_name: '',
  owner_name: '',
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  SET_OUTBOUND_PREFIX: (state, outbound_prefix) => {
    state.outbound_prefix = outbound_prefix;
  },
  SET_INBOUND_PREFIX: (state, inbound_prefix) => {
    state.inbound_prefix = inbound_prefix;
  },
  SET_OUTBOUND_SD: (state, outbound_sd) => {
    state.outbound_sd = outbound_sd;
  },
  SET_INBOUND_SD: (state, inbound_sd) => {
    state.inbound_sd = inbound_sd;
  },
  SET_COMPANY_NAME: (state, company_name) => {
    state.company_name = company_name;
  },
  SET_OWNER_NAME: (state, owner_name) => {
    state.owner_name = owner_name;
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  },
  getSettings({ commit, state }){
    return new Promise((resolve, reject) => {
      fetchSettings(1)
        .then(response => {
          const { data } = response;
          // console.log(response);
          const { outbound_prefix, inbound_prefix, inbound_sd, outbound_sd, owner_name, company_name } = data;
          commit('SET_OUTBOUND_PREFIX', outbound_prefix);
          commit('SET_INBOUND_PREFIX', inbound_prefix);
          commit('SET_INBOUND_SD', inbound_sd);
          commit('SET_OUTBOUND_SD', outbound_sd);
          commit('SET_COMPANY_NAME', company_name);
          commit('SET_OWNER_NAME', owner_name);
          resolve(data);
        });
    });
  },
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
};

