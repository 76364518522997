/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';
const billRoutes = {
  path: '/bill',
  component: Layout,
  redirect: '/bill',
  name: 'Bill',
  alwaysShow: true,
  meta: {
    title: 'Bill',
    icon: 'Wallet',
    permissions: ['view menu bill'],
  },
  children: [
    /** Client Bill managements */
    {
      path: 'client-bill/list',
      component: () => import('../views/bill/ClientBill/List'),
      name: 'clientBill',
      meta: { title: 'clientBillList', icon: 'Memo' },
    },

    {
      path: 'client-bill/create',
      component: () => import('../views/bill/ClientBill/Create'),
      name: 'createClientBill',
      meta: { title: 'createBill', icon: 'Memo', noCache: true, permissions: ['manage bill'] },
      hidden: true,
    },

    {
      path: 'client-bill/view/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/View'),
      name: 'viewBill',
      meta: { title: 'viewBill', icon: 'Memo', noCache: true, permissions: ['view client bill'] },
      hidden: true,
    },

    // bill format
    {
      path: 'bill-format',
      component: () => import('../views/bill/ClientBill/BillFormat/Index'),
      name: 'billformat',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/view/general-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/General/GeneralFormatView'),
      name: 'GeneralFormatView',
      meta: { title: 'GeneralFormatView', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/receive/general-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/General/GeneralFormatReceive'),
      name: 'GeneralFormatReceive',
      meta: { title: 'GeneralFormatView', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/general-format',
      component: () => import('../views/bill/ClientBill/BillFormat/General/General'),
      name: 'billformatGeneral',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/ksr-format',
      component: () => import('../views/bill/ClientBill/BillFormat/KsrFormat/KsrFormat'),
      name: 'billformatKsr',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/view/ksr-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/KsrFormat/KsrFormatView'),
      name: 'billformatKsrView',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/receive/ksr-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/KsrFormat/KsrFormatReceive'),
      name: 'billformatKsrReceive',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/gp-format',
      component: () => import('../views/bill/ClientBill/BillFormat/GpFormat/GpFormat'),
      name: 'billformatGp',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/view/gp-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/GpFormat/GpFormatView'),
      name: 'billformatGpView',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/receive/gp-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/GpFormat/GpFormatReceive'),
      name: 'billformatGpReceive',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/edotco-format',
      component: () => import('../views/bill/ClientBill/BillFormat/EdotcoFormat/EdotcoFormat'),
      name: 'billformatEdotco',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/view/edotco-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/EdotcoFormat/EdotcoFormatView'),
      name: 'billformatEdotcoView',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'bill-format/receive/edotco-format/:id(\\d+)',
      component: () => import('../views/bill/ClientBill/BillFormat/EdotcoFormat/EdotcoFormatReceive'),
      name: 'billformatEdotcoReceive',
      meta: { title: 'billformat', icon: 'Memo' },
      hidden: true,
    },

    /** Subcontractor Bill managements */
    {
      path: 'subcontractor-bill/list',
      component: () => import('../views/bill/SubcontractorBill/List'),
      name: 'subcontractorBill',
      meta: { title: 'subcontractorBillList', icon: 'Memo' },
    },
    {
      path: 'subcontractor-bill/create',
      component: () => import('../views/bill/SubcontractorBill/Create'),
      name: 'createSubcontractorBill',
      meta: { title: 'createBill', icon: 'Memo', noCache: true, permissions: ['view subcontractor bill'] },
      hidden: true,
    },
    {
      path: 'subcontractor-bill/view/:id(\\d+)',
      component: () => import('../views/bill/SubcontractorBill/View'),
      name: 'viewSubcontractorBill',
      meta: { title: 'viewBill', icon: 'Memo', noCache: true, permissions: ['view subcontractor bill'] },
      hidden: true,
    },
    {
      path: 'subcontractor-bill/check/:id(\\d+)',
      component: () => import('../views/bill/SubcontractorBill/Check'),
      name: 'checkBill',
      meta: { title: 'checkBill', icon: 'Memo', noCache: true, permissions: ['check subcontractor bill'] },
      hidden: true,
    },
    {
      path: 'subcontractor-bill/approve/:id(\\d+)',
      component: () => import('../views/bill/SubcontractorBill/Approve'),
      name: 'approveill',
      meta: { title: 'approveBill', icon: 'Memo', noCache: true, permissions: ['approve subcontractor bill'] },
      hidden: true,
    },
  ],
};

export default billRoutes;
