// set function parseTime,formatTime to filter
import moment from 'moment';

import { parseTime, formatTime } from '@/utils';

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

function uppercaseFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

function formatDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

function formateNumber(number, { round = false } = {}) {
  const formattedNumber = round ? numberRound(number) : number;
  return Number(formattedNumber).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function numberRound(number) {
  return parseFloat(number).toFixed(2);
}

export {
  formatDate,
  formateNumber,
  numberRound,
  uppercaseFirst,
  toThousandFilter,
  numberFormatter,
  timeAgo,
  pluralize,
  parseTime,
  formatTime
}
