
import * as helperFunctions from './filters';

export default {
  install(app) {

    if (!app.config.globalProperties['helpers']) {
      app.config.globalProperties['$helpers'] = {};
    }

    for (const functionName in helperFunctions) {
      app.config.globalProperties['$helpers'][functionName] = helperFunctions[functionName];
    }
  },
};
