import router, { resetRouter } from '@/router';
import store from '@/store';

const state = {
  id: null,
  supplier: null,
  name: '',
  company: '',
  phone: '',
  country: '',
  state: '',
  district: '',
  city: '',
  zip_code: '',
  street: '',
  image: '',
  email: '',
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },

  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_COMPANY: (state, company) => {
    state.company = company;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_COUNTRY: (state, country) => {
    state.country = country;
  },
  SET_STATE: (state, states) => {
    state.states = states;
  },
  SET_DISTRICT: (state, district) => {
    state.district = district;
  },
  SET_CITY: (state, city) => {
    state.city = city;
  },
  SET_ZIP_CODE: (state, zip_code) => {
    state.zip_code = zip_code;
  },
  SET_STREET: (state, street) => {
    state.street = street;
  },
  SET_IMAGE: (state, image) => {
    state.image = image;
  },
};

const actions = {
  // user login
  // login({ commit }, userInfo) {
  //   const { email, password } = userInfo;
  //   return new Promise((resolve, reject) => {
  //     login({ email: email.trim(), password: password })
  //       .then(response => {
  //         setLogged('1');
  //         resolve();
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         reject(error);
  //       });
  //   });
  // },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const { data } = response;

          const { phone, email, company, name, country, state, district, zip_code, street, image, id } = data;

          commit('SET_NAME', name);
          commit('SET_PHONE', phone);
          commit('SET_EMAIL', email);
          commit('SET_COUNTRY', country);
          commit('SET_STATE', state);
          commit('SET_DISTRICT', district);
          commit('SET_ZIP_CODE', zip_code);
          commit('SET_STREET', street);
          commit('SET_IMAGE', image);
          commit('SET_COMPANY', company);
          commit('SET_ID', id);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  // logout({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     logout()
  //       .then(() => {
  //         commit('SET_TOKEN', '');
  //         commit('SET_ROLES', []);
  //         removeToken();
  //         resetRouter();
  //         resolve();
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     commit('SET_TOKEN', '');
  //     commit('SET_ROLES', []);
  //     removeToken();
  //     resolve();
  //   });
  // },

  // Dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async resolve => {
  // const token = role + '-token';

  // commit('SET_TOKEN', token);
  // setLogged(token);

  // const { roles } = await dispatch('getInfo');

  // const roles = [role.name];
  // const permissions = role.permissions.map(permission => permission.name);
  // commit('SET_ROLES', roles);
  // commit('SET_PERMISSIONS', permissions);
  // resetRouter();

  // generate accessible routes map based on roles
  // const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, permissions });

  // dynamically add accessible routes
  //   router.addRoutes(accessRoutes);

  //   resolve();
  // });
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
