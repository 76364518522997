
const state = {
  id: null,
  category: null,
  name: '',
  code: '',
  description: '',
  status: '',
  image: '',
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },

  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_CODE: (state, code) => {
    state.code = code;
  },
  SET_DESCRIPTION: (state, description) => {
    state.description = description;
  },
  SET_STATUS: (state, status) => {
    state.status = status;
  },
  SET_IMAGE: (state, image) => {
    state.image = image;
  },
};

const actions = {

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const { data } = response;

          const { name, code, status, description, image, id } = data;

          commit('SET_NAME', name);
          commit('SET_CODE', code);
          commit('SET_DESCRIPTION', description);
          commit('SET_STATUS', status);
          commit('SET_IMAGE', image);
          commit('SET_ID', id);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
