import { createI18n } from 'vue-i18n'; // Update import statement

import Cookies from 'js-cookie';
// import elementEnLocale from 'element-plus/lib/locale/lang/en'; // element-plus lang
// import elementRuLocale from 'element-plus/lib/locale/lang/ru-RU'; // element-plus lang
// import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'; // element-plus lang
// import elementViLocale from 'element-plus/lib/locale/lang/vi'; // element-plus lang
import enLocale from './en';
import ruLocale from './ru';
import zhLocale from './zh';
import viLocale from './vi';

// const messages = {
//   en: {
//     ...enLocale,
//     ...elementEnLocale,
//   },
//   ru: {
//     ...ruLocale,
//     ...elementRuLocale,
//   },
//   zh: {
//     ...zhLocale,
//     ...elementZhLocale,
//   },
//   vi: {
//     ...viLocale,
//     ...elementViLocale,
//   },
// };

const messages = {
  en: {
    ...enLocale
  },
  ru: {
    ...ruLocale
  },
  zh: {
    ...zhLocale
  },
  vi: {
    ...viLocale
  },
};


export function getLanguage() {
  const chooseLanguage = Cookies.get('language');
  if (chooseLanguage) {
    return chooseLanguage;
  }

  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}

const i18n = createI18n({
  // Create i18n instance using createI18n
  // set locale
  // options: en | ru | vi | zh
  locale: getLanguage(),
  // set locale messages
  messages,
});

export default i18n;
