<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <div class="mr-5">
          <!--   <el-tooltip content="Close Sale Register" effect="dark" placement="bottom">
            <el-button
              size="mini"
              type="danger"
              style="padding: 5px"
              @click="handleClose"
            >Close Register</el-button>
          </el-tooltip> -->
        </div>
        <div>
          <el-tooltip content="Create New Sale" effect="dark" placement="bottom">
            <router-link to="/sales/create">
              <el-button size="mini" type="success" style="padding: 5px">POS</el-button>
            </router-link>
          </el-tooltip>
        </div>

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="el-dropdown-link avatar-wrapper">
          <img :src="getUserImage() + image" class="user-avatar" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/dashboard">
              <el-dropdown-item>{{ $t('navbar.dashboard') }}</el-dropdown-item>
            </router-link>
            <router-link v-show="userId !== null" :to="`/profile/edit`">
              <el-dropdown-item>{{ $t('navbar.profile') }}</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display:block;" @click="logout">{{ $t('navbar.logOut') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- close Sale Register modal -->
    <el-dialog title="Close Register" v-model="dialogFormVisible" width="50%">
      <el-form
        :model="registerData"
        :rules="rules"
        ref="registerForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="Closing Balance" prop="closing_balance">
          <el-input
            v-model="registerData.closing_balance"
            placeholder="Please Enter Opening Balance"
          ></el-input>
        </el-form-item>
        <el-form-item label="Closing Note">
          <el-input
            type="textarea"
            rows="2"
            v-model="registerData.note"
            placeholder="Please Enter Closing Note"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
       <!-- <el-button type="primary" @click="closeRegister">Confirm</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
// import LangSelect from '@/components/LangSelect';
import Search from '@/components/HeaderSearch';
import Resource from '@/api/resource';
import axios from 'axios';
const registerResource = new Resource('sale-register');
import EventBus from '@/eventBus';

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    // LangSelect,
    Search,
  },
  data() {
    return {
      imageURL: import.meta.env.VITE_AWS_IMAGE_URL,
      dialogFormVisible: false,
      registerData: {
        closing_balance: 0,
      },

      rules: {
        closing_balance: [
          {
            required: true,
            message: 'Closing Balance is required',
            trigger: 'blur',
          },
        ],
      },

    };
  },
  computed: {
    ...mapGetters(['sidebar', 'name', 'avatar', 'device', 'userId', 'image']),
  },
  methods: {

    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },

    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },

    getUserImage(image) {
      return this.imageURL;
    },

    handleClose() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs['registerForm'].clearValidate();
      });
    },

    closeRegister() {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          axios
            .post('/api/close-sale-register', this.registerData)
            .then(({ data }) => {
              this.$message({
                message: 'Register Closed successfully.',
                type: 'success',
              });
              EventBus.$emit('register-closed');
              this.registerData = {
                closing_balance: 0,
                note: '',
              };
              this.dialogFormVisible = false;
              this.$router.push('/');
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.mr-5 {
  margin-right: 5px;
}
</style>
