<template>
  <!-- Externl Link -->
  <template v-if="isExternalLink(to)">
    <a :href="to" target="_blank" rel="noopener">
      <slot></slot>
    </a>
  </template>

  <!-- Router Link -->
  <template v-else>
    <router-link :to="to">
      <slot></slot>
    </router-link>
  </template>
</template>

<script>
import { isExternal } from '@/utils/validate';

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath);
    }
  },
};
</script>
