/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const accountsRoutes = {
  path: '/accounts',
  component: Layout,
  redirect: '/accounts/users',
  name: 'accounts',
  alwaysShow: true,
  meta: {
    title: 'Accounts',
    icon: 'Operation',
    permissions: ['view menu accounts'],
  },
  children: [
    /** accounts managements */
    // Bank -- *khalif
    {
      path: 'banks',
      component: () => import('../views/bank/List'),
      name: 'BankList',
      meta: { title: 'bankList', icon: 'Memo' },
    },
    {
      path: 'bank/edit/:id(\\d+)',
      component: () => import('../views/bank/components/BankEdit'),
      name: 'EditBank',
      meta: {
        title: 'bankProfile',
        noCache: true,
        permissions: ['manage client'],
      },
      hidden: true,
    },
    {
      path: 'bank-cheque/list',
      component: () => import('../views/bank-cheque/List'),
      name: 'bankChequeList',
      meta: { title: 'Bank Cheque List', icon: 'Memo' },
    },
    {
      path: 'bank-cheque/view/:id(\\d+)',
      component: () => import('../views/bank-cheque/components/Edit'),
      name: 'bankChequeEdit',
      meta: { title: 'Bank Cheque View', icon: 'Memo' },
      hidden: true,
    },
    {
      path: 'groups/list',
      component: () => import('../views/group/List'),
      name: 'groupList',
      meta: { title: 'groupList', icon: 'Memo', permissions: ['add group'] },
    },
    {
      path: 'groups/edit/:id(\\d+)',
      component: () => import('../views/group/Edit'),
      name: 'groupUpdate',
      meta: {
        title: 'groupUpdate',
        icon: 'Memo',
        permissions: ['update group'],
      },
      hidden: true,
    },
    {
      path: 'groups/Create',
      component: () => import('../views/group/Create'),
      name: 'groupCreate',
      meta: {
        title: 'groupCreate',
        icon: 'Memo',
        permissions: ['view group list'],
      },
      hidden: true,
    },
    {
      path: 'parent-group/list',
      component: () => import('../views/parent-group/List'),
      name: 'parentGroupList',
      meta: {
        title: 'parentGroupList',
        icon: 'Memo',
        permissions: ['view parent group list'],
      },
      hidden: true,
    },

    {
      path: 'default-group/list',
      component: () => import('../views/default-group/List'),
      name: 'defaultGroupList',
      meta: {
        title: 'defaultGroupList',
        icon: 'Memo',
        permissions: ['view default group list'],
      },
      hidden: true,
    },
    {
      path: 'ledgers/list',
      component: () => import('../views/ledger/List'),
      name: 'ledgerList',
      meta: {
        title: 'ledgerList',
        icon: 'Memo',
        permissions: ['view ledger list'],
      },
    },
    {
      path: 'ledgers/edit/:id(\\d+)',
      component: () => import('../views/ledger/Edit'),
      name: 'ledgerpUpdate',
      meta: {
        title: 'ledgerUpdate',
        icon: 'Memo',
        permissions: ['update ledger'],
      },
      hidden: true,
    },
    {
      path: 'ledgers/Create',
      component: () => import('../views/ledger/Create'),
      name: 'ledgerCreate',
      meta: {
        title: 'ledgerCreate',
        icon: 'Memo',
        permissions: ['add ledger'],
      },
      hidden: true,
    },

    {
      path: 'journals/list',
      component: () => import('../views/journal/List'),
      name: 'journalList',
      meta: {
        title: 'journalList',
        icon: 'Memo',
        permissions: ['view journal list'],
      },
    },
    {
      path: 'journals/Create',
      component: () => import('../views/journal/Create'),
      name: 'journalCreate',
      meta: {
        title: 'journalCreate',
        icon: 'Memo',
        permissions: ['add journal'],
      },
      hidden: true,
    },
    {
      path: 'journals/view/:id(\\d+)',
      component: () => import('../views/journal/View'),
      name: 'journalView',
      meta: {
        title: 'journalView',
        icon: 'Memo',
        permissions: ['view journal'],
      },
      hidden: true,
    },
    {
      path: 'journals/edit/:id(\\d+)',
      component: () => import('../views/journal/Edit'),
      name: 'journalUpdate',
      meta: {
        title: 'journalUpdate',
        icon: 'Memo',
        permissions: ['update journal'],
      },
      hidden: true,
    },
    {
      path: 'reports',
      component: () => import('../views/accounts/Report'),
      name: 'accountsReport',
      meta: { title: 'Reports', icon: 'Memo', permissions: ['view reports'] },
    },
    {
      path: 'view/cost-center-report',
      component: () => import('../views/accounts/CostCenterReport'),
      name: 'viewCostCenterReport',
      meta: {
        title: 'viewCostCenterReport',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
    {
      path: 'view/ledger-report',
      component: () => import('../views/accounts/LedgerReport'),
      name: 'viewLedgerReport',
      meta: {
        title: 'viewLedgerReport',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
    {
      path: 'view/trial-balance-report',
      component: () => import('../views/accounts/TrialBalanceReport'),
      name: 'viewTrialBalanceReport',
      meta: {
        title: 'View Trial Balance Report',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },

    {
      path: 'view/group-summary-report',
      component: () => import('../views/accounts/groupSummaryReport'),
      name: 'viewGroupSummaryReport',
      meta: {
        title: 'View Group Summary Report',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
    {
      path: 'view/profit-loss-report',
      component: () => import('../views/accounts/ProfitLossReport'),
      name: 'viewProfitLossReport',
      meta: {
        title: 'View Profit Loss Report',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
    {
      path: 'view/day-book-report',
      component: () => import('../views/accounts/DayBookReport'),
      name: 'viewDayBookReport',
      meta: {
        title: 'View day book Report',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
    {
    path: 'view/post-dated-cheque',
    component: () => import('../views/accounts/PostDatedCheque'),
    name: 'viewPostDatedCheque',
    meta: {
      title: 'View PostDatedCheque',
      icon: 'Memo',
      permissions: ['view reports'],
    },
    hidden: true,
  },
    {
      path: 'view/balance-sheet',
      component: () => import('../views/accounts/BalanceSheet'),
      name: 'viewBalanceSheet',
      meta: {
        title: 'View Balance Sheet',
        icon: 'Memo',
        permissions: ['view reports'],
      },
      hidden: true,
    },
  ],
};

export default accountsRoutes;
