import { createRouter, createWebHistory } from 'vue-router'; // Import necessary functions from vue-router

/* Layout */
import Layout from '@/layout';

import accountsRoutes from '~/Modules/Accounts/Resources/js/router/accounts';
import adminRoutes from '~/Modules/Administrator/Resources/js/router/admin';
import billRoutes from '~/Modules/Bill/Resources/js/router/bill';
import inventoryRoutes from '~/Modules/Inventory/Resources/js/router/inventory';
import purchaseRoutes from '~/Modules/Purchase/Resources/js/router/purchase';
import salesRoutes from '~/Modules/Sales/Resources/js/router/sale';
import updatesRoutes from '~/Modules/WbcUpdate/Resources/js/router/updates';
import projectsRoutes from '~/Modules/Project/Resources/js/router/project';
import settingsRoutes from './modules/setting';
import projectStatusRoutes from '~/Modules/Project/Resources/js/router/project-status';

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/AuthRedirect'),
    hidden: true,
  },
  {
    path: '/404',
    redirect: { name: 'Page404' },
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: 'dashboard', icon: 'home-filled', noCache: false },
      },
    ],
  },

  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/edit',
    children: [
      {
        path: 'edit',
        component: () =>
          import(
            '~/Modules/Administrator/Resources/js/views/users/SelfProfile.vue'
          ),
        name: 'SelfProfile',
        meta: { title: 'userProfile', icon: 'user', noCache: true },
      },
    ],
  },
];

export const asyncRoutes = [
  adminRoutes,
  inventoryRoutes,
  salesRoutes,
  purchaseRoutes,
  projectsRoutes,
  updatesRoutes,
  billRoutes,
  projectStatusRoutes,
  accountsRoutes,
  settingsRoutes,

  { path: "/:catchAll(.*)", redirect: '/404', hidden: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes.concat(asyncRoutes),
});


export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    routes: constantRoutes.concat(asyncRoutes),
  });
  router.matcher = newRouter.matcher;
}

export default router;
