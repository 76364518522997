import request from '@/utils/request';
import Resource from '@/api/resource';

/**
 * All Settings Api call from here.
 *
 * @author	bitbyte
 * @since	v0.0.1
 * @version	v1.0.0	Wednesday, February 10th, 2021.
 * @see		Resource
 * @global
 */
export class SettingsResource extends Resource {
  constructor() {
    super('settings');
  }
}

/**
 * a single settings api call from here
 * @var		export	functio
 * @global
 */
export function fetchSettings(id) {
  return request({
    url: '/settings/' + id,
    method: 'get',
  });
}
