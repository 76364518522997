export default {
  route: {
    InvoicePrint: 'Invoice Print',
    dashboard: 'Dashboard',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directives',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'BackToTop',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    treeTable: 'Tree Table',
    customTreeTable: 'Custom TreeTable',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Articles',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    elementUi: 'Element UI',
    administrator: 'Administrator',
    users: 'Users',
    userProfile: 'User Profile',
    supplierList: 'Supplier List',
    supplierProfile: 'Supplier Profile',
    categoryList: 'Category List',
    clientList: 'Client List',
    clientProfile: 'Client Profile',
    bankList: 'Bank List',
    bankProfile: 'Bank Profile',
    subcontractorList: 'Subcontractor List',
    subcontractorProfile: 'Subcontractor Profile',
    productList: 'Product List',
    productEdit: 'Product Edit',
    brandList: 'Brand List',
    unitList: 'Unit List',
    inventory: 'Inventory',
    purchase: 'Purchase',
    purchaseList: 'Purchase List',
    settings: 'Settings',
    warehouseList: 'Warehouse List',
    createPurchase: 'Purchase Create',
    viewPurchase: 'Purchase View',
    editPurchase: 'Purchase Edit',
    EditBrand: 'Brand Edit',
    EditCategory: 'Category Edit',
    activityList: 'Activity List',
    projectTypeList: 'Project Type',
    projectTypeEstimateList: 'Project Type Estimate',
    projectTypeEstimateCreate: 'Project Type Estimate Create',
    projectTypeEstimateEdit: 'Project Type Estimate Edit',
    ProjectTypeEdit: 'Project Type Edit',
    AddProjectTypeMeta: 'Add Project Type Meta',
    projectTypeCreate: 'Create Project Type',
    entryPinUpdate: 'Update Entry Pin',
    projectCreate: 'Create Project',
    projectList: 'Project List',
    inboundInvoiceView: 'Inbound Invoice View',
    generateInvoice: 'Generate Invoice',
    WorkOrder: 'Work Order',
    projectView: 'Project View',
    DepartmentList: 'Department List',
    projectStage: 'Project Stage',
    projectImport: 'Import Project',
    workOrderList: 'Work Order List',
    inboundInvoices: 'Bill Receive',
    outboundInvoices: 'Bill Submit',
    downloadTopsheet: 'Download Topsheet',
    allTopsheet: 'Topsheet List',
    projectRequisition: 'Project Requisition',
    TopsheetView: 'Topsheet View',
    countryList: 'Country List',
    stateList: 'State List',
    districtList: 'District List',
    cityList: 'City List',
    invoices: 'Bill',
    EmployeeList: 'Employee List',
    EmployeeProfile: 'Employee Profile',
    projectReport: 'Project Report',
    clientPriceList: 'Client Price List',
    projectStatus: 'Project Status',
    clientPriceEdit: 'Client Price Edit',
    subcontractorPriceEdit: 'Subcontractor Price Edit',
    subcontractorPriceList: 'Subcontractor Price List',
    quotationEdit: 'Quotation Price Edit',
    quotationList: 'Quotation Price List',
    projectInventoryList: 'Project Inventory List',
    warehouseInventoryList: 'Warehouse Inventory List',
    warehouseInventoryReceiveProduct: 'Warehouse Inventory Receive Product',
    clientBill: 'Client Bill',
    clientBillList: 'Client Bill List',
    subcontractorBill: 'Subcontractor Bill',
    subcontractorBillList: 'Subcontractor Bill List',
    createBill: 'Create Bill',
    ClientBillTopsheetList: 'Client Topsheet List',
    SubcontractorBillTopsheetList: 'Subcontractor Bill Topsheet List',
    SubcontractorBillTopsheetView: 'Subcontractor Bill Topsheet View',
    ClientBillTopsheetView: 'Client Bill Topsheet View',
    charteredAccountList: 'Chartered Account List',
    GeneralFormatView: 'General Format View',
    assignedProjectList: 'Assigned Project List',
    newProjectAssignListEdit: 'Edit Assaigned Project',
    billformat: 'Bill Format',
    projectRequisitionCreate: 'Create New Requisition',
    projectDirasa: 'Project Dirasa',
    projectDirasaCreate: 'Project Dirasa Create',
    ProjectDirasaEdit: 'Project Dirasa Edit',
    projectRequisitionImport: 'Project Requisition Import',
    groupList: 'Group List',
    groupCreate: 'Create New Group',
    groupEdit: 'Edit Group',
    ledgerList: 'Ledger List',
    ledgerCreate: 'Create New Ledger',
    ledgerEdit: 'Edit Group',
    parentGroupList: 'Parent Group List',
    defaultGroupList: 'Default Group List',
    journalList: 'Journal List',
    journalCreate: 'Journal Create',
    journalUpdate: 'Journal Update',
    journalView: 'Journal View',
    projectEdit: 'Project Edit',
    stockList: 'Stock List',
    transferList: 'Transfer List',
    transferView: 'Transfer View',
    transferCreate: 'Create New Transfer',
    deliveryList: 'Delivery List',
    deliveryCreate: 'Create New Delivery',
    deliveryView: 'Delivery View',
    wastageList: 'Wastage List',
    wastageView: 'Wastage View',
    wastageCreate: 'Create New Wastage',
    wastageCheck: 'Wastage Check',
    wastageApprove: 'Wastage Approve',
    consumptionList: 'Consumption List',
    consumptionView: 'Consumption View',
    consumptionCreate: 'Create New Consumption',
    consumptionCheck: 'Consumption Check',
    consumptionApprove: 'Consumption Approve',
    purchaseRequisitionList: 'Purchase Requisition List',
    purchaseRequisitionCreate: 'Create New Purchase Requisition',
    purchaseRequisitionView: 'Purchase Requisition View',
    purchaseRequisitionCheck: 'Purchase Requisition Check',
    purchaseRequisitionApprove: 'Purchase Requisition Approve',
    purchaseOrderList: 'Purchase Order List',
    purchaseOrderCreate: 'Create New Purchase Order',
    purchaseOrderView: 'Purchase Order View',
    purchaseOrderCheck: 'Purchase Order Check',
    purchaseOrderApprove: 'Purchase Order Approve',
    assetList: 'Asset List',
    assetView: 'View Asset',
    assetCreate: 'Create New Asset',
    assetEdit: 'Edit Asset',
    purchaseReturnList: 'Purchase Return List',
    purchaseReturnCreate: 'Create New Purchase Return',
    purchaseReturnEdit: 'Edit Purchase Return',
    purchaseReturnView: 'View Purchase Return',
    viewBill: 'View Bill',
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Theme',
    size: 'Global Size',
    profile: 'Profile',
  },
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !',
    email: 'Email',
  },
  documentation: {
    documentation: 'Documentation',
    laravel: 'Laravel',
    github: 'Github Repository',
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit Permission',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases it is not suitable to use v-role/v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if with checkRole or/and checkPermission.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide',
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.',
  },
  table: {
    description: 'Description',
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    name: 'Name',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    check: 'Check',
    approve: 'Approve',
    keyword: 'Keyword',
    role: 'Role',
    phone: 'Phone',
    user: 'Users',
    createProject: 'Create Project',
    go_next_stage: 'Go Next Stage',
    update: 'Update',
    generateInvoice: 'Generate Invoice',
    finish_project: 'Finish Project',
    finished: 'Finished',
    generateWorkOrder: 'Work Order',
    // slug: 'Slug'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name(default excel-list)',
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)',
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading pdf.',
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo',
  },
  user: {
    'role': 'Role',
    'password': 'Password',
    'confirmPassword': 'Confirm password',
    'email': 'Email',
    'name': 'Name',
    'image': 'Image',
    'selected_image': 'Selected Image',
  },
  employee: {
    'name': 'Name',
    'employee_id': 'Employee ID',
    'email': 'Email',
    'phone': 'Personal Contact Number',
    'street': 'Address',
    'state': 'State',
    'zip_code': 'Post Code',
    'city': 'City',
    'district': 'District',
    'country': 'Country',
    'image': 'Image',
    'selected_image': 'Selected Image',
    'emergency_phone': 'Emergency Contact Number',
    'office_phone': 'Official Contact Number',
    'nid_number': 'NID Number',
    'designation': 'Designation',
    'joining_date': 'Joining Dae',
    'blood_group': 'Blood Group',
    },
    supplier: {
      'company': 'Company Name',
      'password': 'Password',
      'confirmPassword': 'Confirm password',
      'name': 'Name',
      'email': 'Email',
      'phone': 'Phone',
      'street': 'Address',
      'state': 'State',
      'zip_code': 'Post Code',
      'city': 'City',
      'district': 'District',
      'country': 'Country',
      'image': 'Image',
      'trade_licence': 'Trade Licence',
      'TIN': 'TIN',
      'BIN': 'BIN',
      'NID': 'NID',
      'selected_image': 'Selected Image',
      'emergency_phone': 'Emergency Contact Number',
      'office_phone': 'Official Contact Number',
    },
    client: {
      'company': 'Company Name',
      'password': 'Password',
      'confirmPassword': 'Confirm password',
      'name': 'Name',
      'email': 'Email',
      'phone': 'Phone',
      'street': 'Address',
      'state': 'State',
      'zip_code': 'Post Code',
      'city': 'City',
      'district': 'District',
      'country': 'Country',
      'image': 'Image',
      'trade_licence': 'Trade Licence',
      'TIN': 'TIN',
      'BIN': 'BIN',
      'NID': 'NID',
      'selected_image': 'Selected Image',
    },
    subcontractor: {
      'company': 'Company Name',
      'password': 'Password',
      'confirmPassword': 'Confirm password',
      'name': 'Name',
      'email': 'Email',
      'phone': 'Phone',
      'street': 'Address',
      'state': 'State',
      'zip_code': 'Post Code',
      'city': 'City',
      'district': 'District',
      'country': 'Country',
      'image': 'Image',
      'trade_licence': 'Trade Licence',
      'TIN': 'TIN',
      'BIN': 'BIN',
      'NID': 'NID',
      'selected_image': 'Selected Image',
    },
    category: {
      'name': 'Name',
      'code': 'Code',
      'description': 'Description',
      'image': 'Image',
      'selected_image': 'Selected Image',
      // 'slug': 'Slug'
    },
    brand: {
      'name': 'Name',
      'code': 'Code',
      'description': 'Description',
      'image': 'Image',
      'selected_image': 'Selected Image',
    },
    warehouse: {
      'name': 'Name',
      'code': 'Code',
      'email': 'Email',
      'phone': 'Phone',
      'address': 'Address',
    },
    purchase: {
      'entry_date': 'Entry Date',
      'ref_number': 'Ref. Number',
      'purchase_type': 'Purchase Type',
      'supplier_id': 'Supplier',
      'warehouse_id': 'Warehouse',
      'project_id': 'Project',
      'main_unit': 'Main Unit',
      'additional_unit': 'Additional Unit',
      'main_quantity': 'Main Quantity',
      'additional_quantity': 'Additional Quantity',
      'status': 'Status',
      'address': 'Address',
      'createPurchase': 'Purchase Create',
      'product': 'Product',
      'payment_condition': 'Payment Condition',
      'note': 'Note',
      'attachment': 'Attachment',
      'selected_attachment': 'Selected Attachment',
  },
  unit: {
    'name': 'Name',
    'code': 'Code',
  },
  department: {
    'name': 'Name',
    'code': 'Code',
  },
  projectType: {
    'name': 'Name',
    'code': 'Code',
    'stage': 'Stage Number',
  },
  cpl: {
    'client_id': 'Client Name',
    'product_id': 'Product Name',
    'user_id': 'Created By',
    'entry_date': 'Entry Date',
    'delivery_date': 'Delivery Date',
    'status': 'Status',
  },
  spl: {
    'subcontractor_id': 'Subcontractor Name',
    'product_id': 'Product Name',
    'user_id': 'Created By',
    'entry_date': 'Entry Date',
    'delivery_date': 'Delivery Date',
    'status': 'Status',
  },
  quotation: {
    'client_id': 'Client Name',
    'product_id': 'Product Name',
    'user_id': 'Created By',
    'entry_date': 'Entry Date',
    'delivery_date': 'Delivery Date',
    'status': 'Status',
  },
  requisition: {
    'name': 'Name',
    'code': 'Code',
    'phone': 'Phone',
    'address': 'Address',
    'project_type': 'Project Type',
    'quantity': 'Quantity',
    'note': 'Note',
    'status': 'Status',
  },
  stage: {
    'stage_amount': 'Stage Amount',
    'inbound_amount': 'Inbound Amount',
    'outbound_amount': 'Outbound Amount',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'work_done': 'Work Done (%)',
    'finished_date': 'Finished Date',
  },
  projectTypeEstimate: {
    'projectType': 'Project Type',
    'productName': 'Product Name',
    'quantity': 'Product Quantity',
  },
  product: {
    'name': 'Name',
    'code': 'Code',
    'description': 'Description',
    'image': 'Image',
    'selected_image': 'Selected Image',
    'category_id': 'Category Name',
    'brand_id': 'Brand Name',
    'unit_id': 'Unit Name',
    'main_unit': 'Main Unit',
    'additional_unit': 'Additional Unit',
    'main_quantity': 'Main Quantity',
    'additional_quantity': 'Additional Quantity',
    'purchase_unit_name': 'Purchase Unit Name',
    'sell_unit_name': 'Sell Unit Name',
    'sku': 'SKU',
    'price': 'Price',
    'promotion_price': 'Promotion Price',
    'cost': 'Cost',
    'alert_quantity': 'Alert Quantity',
    'meta': 'Product Meta',
  },
  productEstimate: {
    'productName': 'Product Name',
  },
  project: {
    'name': 'Name',
    'work_order_id': 'Work Order ID',
    'project_type': 'Project Type',
    'client_id': 'Client Name',
    'country_id': 'Country Name',
    'state_id': 'State Name',
    'district_id': 'District Name',
    'city': 'City Name',
    'location': 'Project Location / Address',
    'bn_address': 'Address in bangla',
    'gps': 'GPS',
    'details': 'Project Details',
    'coordinator': 'Project Co-ordinator',
    'stage_id': 'Project Stage',
    'meta': 'Project Meta',
    'project_amount': 'Project Amount',
    'deadline': 'Deadline',
    'site_code': 'Site Code',
    'start_date': 'Start Date',
    'agreement_amount': 'Agreement Amount',
    'finished_date': 'Finished Date',
    'quantity': 'Quantity',
    'approved_date': 'Approved Date',
    'signboard_collection_date': 'Signboard Collection Date',
    'signboard_collected_by': 'Signboard Collected By',
    'remark': 'Remark',
    'power_of_attorney': 'Power Of Attorney Date',
  },
  projectSubcontract: {
    'proejct_id': 'Project Name',
    'subcontractor_id': 'Subcontractor Name',
    'task_details': 'Task Details',
    'payable_amount': 'Payable Amount',
    'issue_date': 'Issue Date',
    'deadline': 'Deadline',
    'code': 'Code',
    'order_no': 'Order No',
    'contract_ref': 'Contract Ref',
    'department_id': 'Department',
    'finished_date': 'Finished Date',
    'project_stage_id': 'Project Type',
  },
  bill: {
    'proejct_id': 'Project Name',
    'amount': 'Amount',
    'invoice_id': 'Invoice ID',
    'status': 'Invoice Status',
    'payment_method': 'Payment Method',
    'payment_details': 'Payment Details',
    'date': 'Issue Date',
    'invoice_type': 'Invoice Type',
    'payment_date': 'Payment Date',
    'bill_received_number': 'Bill Received Number',
    'payment_status': 'Payment Status',
  },
  roles: {
    description: {
      admin: 'Super Administrator. Have access and full permission to all pages.',
      manager: 'Manager. Have access and permission to most of pages except permission page.',
      accounts: 'Accounts. Have access to most of pages, full permission with articles and related resources.',
      super_user: 'Super User. Have access and full permission to all pages.',
      staff: 'Staff. Have access to static pages, should not have any writable permission',
      procurements: 'Procurements. Have access to static pages, should not have any writable permission',
    },
  },
};
