/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const projectStatusRoutes = {
  path: '/project-status',
  component: Layout,
  name: 'projectStatus',
  alwaysShow: true,
  meta: {
    title: 'projectStatus',
    icon: 'Orange',
    permissions: ['view menu project status'],
  },
  children: [
    /** projectStatus managements */
    {
      path: 'list',
      component: () => import('../views/projectstatus/List'),
      name: 'projectStatusList',
      meta: {
        title: 'projectStatus',
        icon: 'Memo',
        permissions: ['manage project status'],
      },
    },
    {
      path: 'view/:id(\\d+)',
      component: () => import('../views/projectstatus/components/Edit'),
      name: 'projectstatusEdit',
      meta: {
        title: 'projectstatusEdit',
        noCache: true,
        permissions: ['manage project status'],
      },
      hidden: true,
    },
  ],
};

export default projectStatusRoutes;
