/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const purchaseRoutes = {
  path: '/purchase',
  component: Layout,
  redirect: '/purchase',
  name: 'Purchase',
  alwaysShow: true,
  meta: {
    title: 'purchase',
    icon: 'ShoppingCart',
    permissions: ['view menu purchase'],
  },
  children: [
    /** Purchase managements */
    // Requisition routes
    {
      path: 'requisitions/list',
      component: () => import('../views/purchase-requisition/List'),
      name: 'purchaseRequisitionList',
      meta: { title: 'purchaseRequisitionList', icon: 'Memo', permissions: ['view purchase requisition list'] },
    },
    {
      path: 'requisitions/Create',
      component: () => import ('../views/purchase-requisition/Create'),
      name: 'purchaseRequisitionCreate',
      meta: { title: 'purchaseRequisitionCreate', icon: 'Memo', permissions: ['add purchase requisition']  },
      hidden: true,
    },
    {
      path: 'requisitions/view/:id',
      component: () => import ('../views/purchase-requisition/View'),
      name: 'purchaseRequisitionView',
      meta: { title: 'purchaseRequisitionView', icon: 'Memo', permissions: ['view purchase requisition']  },
      hidden: true,
    },
    {
      path: 'requisitions/edit/:id',
      component: () => import ('../views/purchase-requisition/Edit'),
      name: 'purchaseRequisitionEdit',
      meta: { title: 'purchaseRequisitionEdit', icon: 'Memo', permissions: ['edit purchase requisition']  },
      hidden: true,
    },
    {
      path: 'requisitions/check/:id',
      component: () => import ('../views/purchase-requisition/Edit'),
      name: 'purchaseRequisitionCheck',
      meta: { title: 'purchaseRequisitionCheck', icon: 'Memo', permissions: ['check purchase requisition']  },
      hidden: true,
    },
    {
      path: 'requisitions/approve/:id',
      component: () => import ('../views/purchase-requisition/Edit'),
      name: 'purchaseRequisitionApprove',
      meta: { title: 'purchaseRequisitionApprove', icon: 'Memo', permissions: ['approve purchase requisition']  },
      hidden: true,
    },

    // Order routes
    {
      path: 'orders/list',
      component: () => import('../views/purchase-order/List'),
      name: 'purchaseOrderList',
      meta: { title: 'purchaseOrderList', icon: 'Memo', permissions: ['view purchase order list'] },
    },
    {
      path: 'orders/Create',
      component: () => import ('../views/purchase-order/Create'),
      name: 'purchaseOrderCreate',
      meta: { title: 'purchaseOrderCreate', icon: 'Memo', permissions: ['add purchase order']  },
      hidden: true,
    },
    {
      path: 'orders/view/:id',
      component: () => import ('../views/purchase-order/View'),
      name: 'purchaseOrderView',
      meta: { title: 'purchaseOrderView', icon: 'Memo', permissions: ['view purchase order']  },
      hidden: true,
    },
    {
      path: 'orders/edit/:id',
      component: () => import ('../views/purchase-order/Edit'),
      name: 'purchaseOrderEdit',
      meta: { title: 'purchaseOrderEdit', icon: 'Memo', permissions: ['edit purchase order']  },
      hidden: true,
    },
    {
      path: 'orders/check/:id',
      component: () => import ('../views/purchase-order/Edit'),
      name: 'purchaseOrderCheck',
      meta: { title: 'purchaseOrderCheck', icon: 'Memo', permissions: ['check purchase order']  },
      hidden: true,
    },
    {
      path: 'orders/approve/:id',
      component: () => import ('../views/purchase-order/Edit'),
      name: 'purchaseOrderApprove',
      meta: { title: 'purchaseOrderApprove', icon: 'Memo', permissions: ['approve purchase order']  },
      hidden: true,
    },


    // Purchase routes
    {
      path: 'purchases/list',
      component: () => import('../views/purchase/List'),
      name: 'purchaseList',
      meta: { title: 'purchaseList', icon: 'Memo', permissions: ['view purchases list'] },
    },

    {
      path: 'purchases/create',
      component: () => import('../views/purchase/Create'),
      name: 'createPurchase',
      meta: { title: 'createPurchase', icon: 'edit', noCache: true, permissions: ['add purchase'] },
      hidden: true,
    },
    {
      path: 'purchases/view/:id',
      component: () => import('../views/purchase/View'),
      name: 'viewPurchase',
      meta: { title: 'viewPurchase', icon: 'edit', noCache: true, permissions: ['view purchase'] },
      hidden: true,
    },
    {
      path: 'purchases/edit/:id',
      component: () => import('../views/purchase/Edit'),
      name: 'editPurchase',
      meta: { title: 'editPurchase', icon: 'edit', noCache: true, permissions: ['update purchase'] },
      hidden: true,
    },


    /** Purchase Return routes */
    {
      path: 'returns/list',
      component: () => import('../views/purchase-return/List'),
      name: 'purchaseReturnList',
      meta: { title: 'purchaseReturnList', icon: 'Memo', permissions: ['view purchases list'] },
    },
    {
      path: 'returns/create/:id',
      component: () => import('../views/purchase-return/Create'),
      name: 'createPurchaseReturn',
      meta: { title: 'createPurchaseReturn', icon: 'add', noCache: true, permissions: ['add purchase'] },
      hidden: true,
    },
    {
      path: 'returns/view/:id',
      component: () => import('../views/purchase-return/View'),
      name: 'viewPurchaseReturn',
      meta: { title: 'viewPurchaseReturn', icon: 'view', noCache: true, permissions: ['view purchase'] },
      hidden: true,
    },
    {
      path: 'returns/edit/:id',
      component: () => import('../views/purchase-return/Edit'),
      name: 'editPurchaseReturn',
      meta: { title: 'editPurchaseReturn', icon: 'edit', noCache: true, permissions: ['update purchase'] },
      hidden: true,
    },
    {
      path: 'purchase-report',
      component: () => import('../views/purchase/purchase-report'),
      name: 'purchaseReport',
      meta: {
        title: 'purchase Report',
        icon: 'Memo',
       // permissions: ['view sale list'],
      },
      hidden: true,
    },
  ],
};

export default purchaseRoutes;
