/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const adminRoutes = {
  path: '/administrator',
  component: Layout,
  redirect: '/administrator/users',
  name: 'Administrator',
  alwaysShow: true,
  meta: {
    title: 'administrator',
    icon: 'avatar',
    permissions: ['view menu administrator'],
  },
  children: [
    /** User managements */
    {
      path: 'users/edit/:id(\\d+)',
      component: () => import('../views/users/UserProfile'),
      name: 'UserProfile',
      meta: {
        title: 'userProfile',
        noCache: true,
        permissions: ['manage user'],
      },
      hidden: true,
    },
    {
      path: 'users',
      component: () => import('../views/users/List'),
      name: 'UserList',
      meta: { title: 'users', icon: 'user', permissions: ['manage user'] },
    },
    {
      path: 'edit/user/permission/:id(\\d+)',
      component: () => import('../views/users/components/Permisions'),
      name: 'EditUserPermission',
      meta: {
        title: 'EditPermission',
        icon: 'edit',
        permissions: ['manage permission'],
      },
      hidden: true,
    },
    // employees management

    {
      path: 'employees',
      component: () => import('../views/employees/List'),
      name: 'EmployeeList',
      meta: {
        title: 'EmployeeList',
        icon: 'Memo',
        permissions: ['manage employee'],
      },
    },
    {
      path: 'employees/edit/:id(\\d+)',
      component: () => import('../views/employees/components/EditEmployee'),
      name: 'EmployeeProfile',
      meta: {
        title: 'EmployeeProfile',
        noCache: true,
        permissions: ['update employee'],
      },
      hidden: true,
    },
    /** Role and permission */
    {
      path: 'roles',
      component: () => import('../views/role-permission/List'),
      name: 'RoleList',
      meta: {
        title: 'rolePermission',
        icon: 'EditPen',
        permissions: ['manage permission'],
      },
    },
    {
      path: 'edit/permission/:id(\\d+)',
      component: () => import('../views/role-permission/Permisions'),
      name: 'EditPermission',
      meta: {
        title: 'EditPermission',
        icon: 'edit',
        permissions: ['manage permission'],
      },
      hidden: true,
    },
    // {
    //   path: 'articles/edit/:id(\\d+)',
    //   component: () => import('../views/articles/Edit'),
    //   name: 'EditArticle',
    //   meta: { title: 'editArticle', noCache: true, permissions: ['manage article'] },
    //   hidden: true,
    // },
    // {
    //   path: 'articles',
    //   component: () => import('../views/articles/List'),
    //   name: 'ArticleList',
    //   meta: { title: 'articleList', icon: 'list', permissions: ['manage article'] },
    // },
    // supplier routes
    {
      path: 'suppliers',
      component: () => import('../views/supplier/List'),
      name: 'supplierList',
      meta: { title: 'supplierList', icon: 'Memo' },
    },
    {
      path: 'suppliers/edit/:id(\\d+)',
      component: () => import('../views/supplier/components/SupplierEdit'),
      name: 'EditSupplier',
      meta: {
        title: 'supplierProfile',
        noCache: true,
        permissions: ['manage supplier'],
      },
      hidden: true,
    },
    // clients routes
    {
      path: 'clients',
      component: () => import('../views/client/List'),
      name: 'ClientList',
      meta: { title: 'clientList', icon: 'Memo' },
    },
    {
      path: 'clients/edit/:id(\\d+)',
      component: () => import('../views/client/components/ClientEdit'),
      name: 'EditClient',
      meta: {
        title: 'clientProfile',
        noCache: true,
        permissions: ['manage client'],
      },
      hidden: true,
    },

    // subcontractors routes
    {
      path: 'subcontractors',
      component: () => import('../views/subcontractor/List'),
      name: 'SubcontractorList',
      meta: { title: 'subcontractorList', icon: 'Memo' },
    },
    {
      path: 'subcontractors/edit/:id(\\d+)',
      component: () =>
        import('../views/subcontractor/components/SubcontractorEdit'),
      name: 'EditSubcontractor',
      meta: {
        title: 'subcontractorProfile',
        noCache: true,
        permissions: ['manage subcontractor'],
      },
      hidden: true,
    },
    {
      path: 'departments',
      component: () => import('../views/department/List'),
      name: 'DepartmentList',
      meta: { title: 'DepartmentList', icon: 'Memo' },
    },
    {
      path: 'departments/edit/:id(\\d+)',
      component: () => import('../views/department/components/DepartmentEdit'),
      name: 'DepartmentEdit',
      meta: {
        title: 'DepartmentEdit',
        noCache: true,
        permissions: ['manage department'],
      },
      hidden: true,
    },
    // activity log routes
    {
      path: 'activity-log',
      component: () => import('../views/activity/List'),
      name: 'ActivityList',
      meta: {
        title: 'activityList',
        icon: 'Memo',
        permissions: ['manage activity'],
      },
    },
  ],
};

export default adminRoutes;
