/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const projectsRoutes = {
  path: '/projects',
  component: Layout,
  redirect: '/projects',
  name: 'Projects',
  alwaysShow: true,
  meta: {
    title: 'Projects',
    icon: 'Grape',
    permissions: ['view menu projects'],
  },
  children: [
    /** project type routes */
    {
      path: 'project-type',
      component: () => import('../views/project-type/List'),
      name: 'projectTypeList',
      meta: {
        title: 'projectTypeList',
        icon: 'Memo',
        permissions: ['manage project type'],
      },
    },
    {
      path: 'project-type/create',
      component: () => import('../views/project-type/Create'),
      name: 'projectTypeCreate',
      meta: {
        title: 'projectTypeCreate',
        icon: 'Memo',
        permissions: ['add project type'],
      },
      hidden: true,
    },
    {
      path: 'project-type/edit/:id(\\d+)',
      component: () =>
        import('../views/project-type/components/ProjectTypeEdit'),
      name: 'ProjectTypeEdit',
      meta: {
        title: 'ProjectTypeEdit',
        noCache: true,
        permissions: ['update project type'],
      },
      hidden: true,
    },
    // {
    //   path: 'project-type/meta/:id(\\d+)',
    //   component: () => import('../views/project-type/components/ProjectTypeAddMeta'),
    //   name: 'AddProjectTypeMeta',
    //   meta: { title: 'AddProjectTypeMeta', noCache: true, permissions: ['add project type meta'] },
    //   hidden: true,
    // },

    // new project assign routes
    {
      path: 'new-project-assign-list',
      component: () => import('../views/new-project-assign-list/List'),
      name: 'newAssignedProjectList',
      meta: {
        title: 'Assigned Project List',
        icon: 'Memo',
        permissions: ['manage project assign'],
      },
    },
    {
      path: 'new-project-assign/create',
      component: () =>
        import('../views/new-project-assign-list/components/Create'),
      name: 'newProjectAssignListCreate',
      meta: {
        title: 'newProjectAssignListCreate',
        icon: 'Memo',
        permissions: ['assign new project'],
      },
      hidden: true,
      // props: true,
    },
    {
      path: 'new-project-assign/edit/:id(\\d+)',
      component: () =>
        import('../views/new-project-assign-list/components/Edit'),
      name: 'newProjectAssignListEdit',
      meta: {
        title: 'newProjectAssignListEdit',
        icon: 'Memo',
        permissions: ['update assigned project'],
      },
      hidden: true,
    },
    // project type estimate routes
    {
      path: 'project-type-estimate',
      component: () => import('../views/project-type-estimate/List'),
      name: 'projectTypeEstimateList',
      meta: {
        title: 'projectTypeEstimateList',
        icon: 'Memo',
        permissions: ['manage project type estimate'],
      },
    },
    {
      path: 'project-type-estimate/create',
      component: () => import('../views/project-type-estimate/Create'),
      name: 'projectTypeEstimateCreate',
      meta: {
        title: 'projectTypeEstimateCreate',
        icon: 'Memo',
        permissions: ['add project type estimate'],
      },
      hidden: true,
    },
    {
      path: 'project-type-estimate/edit/:id(\\d+)',
      component: () => import('../views/project-type-estimate/View'),
      name: 'ProjectTypeEstimateEdit',
      meta: {
        title: 'ProjectTypeEstimateEdit',
        noCache: true,
        permissions: ['update project type estimate'],
      },
      hidden: true,
    },

    // project requsition list

    {
      path: 'requisition',
      component: () => import('../views/project-requisition/List'),
      name: 'projectRequisition',
      meta: {
        title: 'projectRequisition',
        icon: 'Memo',
        permissions: ['view requisition list'],
      },
    },
    {
      path: 'requisition/create',
      component: () => import('../views/project-requisition/Create'),
      name: 'projectRequisitionCreate',
      meta: {
        title: 'projectRequisitionCreate',
        icon: 'Memo',
        permissions: ['add project requisition'],
      },
      hidden: true,
    },
    {
      path: 'requisition/import',
      component: () => import('../views/project-requisition/Import'),
      name: 'projectRequisitionImport',
      meta: {
        title: 'projectRequisitionImport',
        icon: 'Memo',
        permissions: ['add requisition'],
      },
      hidden: true,
    },
    {
      path: 'requisition/edit/:id(\\d+)',
      component: () => import('../views/project-requisition/Edit'),
      name: 'ProjectRequisitionEdit',
      meta: {
        title: 'ProjectRequisitionEdit',
        noCache: true,
        permissions: ['update project requisition'],
      },
      hidden: true,
    },

    // project dirasa list

    {
      path: 'dirasa',
      component: () => import('../views/project-dirasa/List'),
      name: 'projectDirasa',
      meta: {
        title: 'projectDirasa',
        icon: 'Memo',
        permissions: ['view dirasa list'],
      },
    },
    {
      path: 'dirasa/create',
      component: () => import('../views/project-dirasa/Create'),
      name: 'projectDirasaCreate',
      meta: {
        title: 'projectDirasaCreate',
        icon: 'Memo',
        permissions: ['add project dirasa'],
      },
      hidden: true,
    },

    {
      path: 'dirasa/edit/:id(\\d+)',
      component: () => import('../views/project-dirasa/Edit'),
      name: 'ProjectDirasaEdit',
      meta: {
        title: 'ProjectDirasaEdit',
        noCache: true,
        permissions: ['update project dirasa'],
      },
      hidden: true,
    },

    // projects routes

    {
      path: 'create',
      component: () => import('../views/project/Create'),
      name: 'projectCreate',
      meta: {
        title: 'projectCreate',
        icon: 'Memo',
        permissions: ['add project'],
      },
      hidden: true,
    },

    {
      path: 'list',
      component: () => import('../views/project/updateList'),
      name: 'projectList',
      meta: {
        title: 'projectList',
        icon: 'Memo',
        permissions: ['add project'],
      },
    },
    {
      path: 'report',
      component: () => import('../views/project-report/List'),
      name: 'projectReport',
      meta: {
        title: 'projectReport',
        icon: 'Memo',
        permissions: ['view project report'],
      },
    },
    {
      path: 'import',
      component: () => import('../views/project/Import'),
      name: 'projectImport',
      meta: {
        title: 'projectImport',
        icon: 'Memo',
        permissions: ['project import'],
      },
    },

    {
      path: 'work-order/list',
      component: () => import('../views/work-order/List'),
      name: 'workOrderList',
      meta: {
        title: 'workOrderList',
        icon: 'Memo',
        permissions: ['view work order list'],
      },
    },
    {
      path: 'work-order/create',
      component: () => import('../views/work-order/Create'),
      name: 'workOrderCreate',
      meta: {
        title: 'workOrderCreate',
        icon: 'Memo',
        permissions: ['add work order'],
      },
      hidden: true,
    },
    {
      path: 'work-order/view/:id(\\d+)',
      component: () => import('../views/work-order/View'),
      name: 'workOrderView',
      meta: {
        title: 'workOrderView',
        icon: 'Memo',
        permissions: ['view work order'],
      },
      hidden: true,
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('../views/project/Edit'),
      name: 'projectEditByID',
      meta: {
        title: 'projectEditByID',
        icon: 'Memo',
        permissions: ['update project'],
      },
      hidden: true,
    },
    {
      path: 'view/:id(\\d+)',
      component: () => import('../views/project/View'),
      name: 'projectView',
      meta: {
        title: 'projectView',
        icon: 'Memo',
        permissions: ['update project'],
      },
      hidden: true,
    },
    {
      path: 'single/view/:id(\\d+)',
      component: () => import('../views/project/SingleView'),
      name: 'projectSingleView',
      meta: {
        title: 'projectSingleView',
        icon: 'Memo',
        permissions: ['update project'],
      },
      hidden: true,
    },
    {
      path: 'stage/:id(\\d+)',
      component: () => import('../views/project/Stage'),
      name: 'projectStage',
      meta: {
        title: 'projectStage',
        icon: 'Memo',
        permissions: ['update project'],
      },
      hidden: true,
    },

    // generate work order
    {
      path: 'work-order/:id(\\d+)',
      component: () =>
        import('../views/project/components/subcontract/WorkOrder'),
      name: 'WorkOrder',
      meta: {
        title: 'WorkOrder',
        icon: 'Memo',
        permissions: ['generate work order'],
      },
      hidden: true,
      props: true,
    },
  //   {
  //     path: 'assigned-list',
  //     component: () =>
  //         import ('../views/assaign-project/List'),
  //     name: 'assignedProjectList',
  //     meta: { title: 'Assign Project User', icon: 'project-list', permissions: ['update project'] },
  //  },
  ],
};

export default projectsRoutes;
