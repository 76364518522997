/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const inventoryRoutes = {
  path: '/inventory',
  component: Layout,
  redirect: '/inventory/users',
  name: 'Inventory',
  alwaysShow: true,
  meta: {
    title: 'inventory',
    icon: 'Grid',
    permissions: ['view menu inventory'],
  },
  children: [
    /** inventory managements */
    {
      path: 'categories/list',
      component: () => import('../views/categories/List'),
      name: 'categoryList',
      meta: { title: 'categoryList', icon: 'Memo' },
    },
    {
      path: 'categories/edit/:id(\\d+)',
      component: () => import('../views/categories/components/CategoryEdit'),
      name: 'EditCategory',
      meta: { title: 'EditCategory', noCache: true, permissions: ['manage category'] },
      hidden: true,
    },
    // brand routes
    {
      path: 'brands/list',
      component: () => import('../views/brand/List'),
      name: 'brandList',
      meta: { title: 'brandList', icon: 'Memo' },
    },
    {
      path: 'brands/edit/:id(\\d+)',
      component: () => import('../views/brand/components/BrandEdit'),
      name: 'EditBrand',
      meta: { title: 'EditBrand', noCache: true, permissions: ['manage brand'] },
      hidden: true,
    },
    // unit routes
    {
      path: 'units/list',
      component: () => import('../views/unit/List'),
      name: 'unitList',
      meta: { title: 'unitList', icon: 'Memo' },
    },
    {
      path: 'units/edit/:id(\\d+)',
      component: () => import('../views/unit/components/UnitEdit'),
      name: 'EditUnit',
      meta: { title: 'EditUnit', noCache: true, permissions: ['manage unit'] },
      hidden: true,
    },

    // product routes
    {
      path: 'product/list',
      component: () => import('../views/product/List'),
      name: 'ProductList',
      meta: { title: 'productList', icon: 'Memo' },
    },
    {
      path: 'products/edit/:id(\\d+)',
      component: () => import('../views/product/components/ProductEdit'),
      name: 'ProductEdit',
      meta: { title: 'ProductEdit', noCache: true, permissions: ['manage product'] },
      hidden: true,
    },
    // product-estimate routes
    {
      path: 'product-estimate/edit/:id',
      component: () => import('../views/product-estimate/View'),
      name: 'ProductEstimateEdit',
      meta: { title: 'ProductEstimateEdit', noCache: true, permissions: ['view product estimate'] },
      hidden: true,
    },
    // production routes
    {
      path: 'production/create/:id',
      component: () => import('../views/production/Create'),
      name: 'ProductionCreate',
      meta: { title: 'ProductionCreate', noCache: true, permissions: ['view product estimate'] },
      hidden: true,
    },
    {
      path: 'production/view/:id',
      component: () => import('../views/production/View'),
      name: 'ProductionView',
      meta: { title: 'ProductionEdit', noCache: true, permissions: ['view product estimate'] },
      hidden: true,
    },
    // warehouse routes
    {
      path: 'warehouses/list',
      component: () => import('../views/warehouse/List'),
      name: 'warehouseList',
      meta: { title: 'warehouseList', icon: 'Memo', permissions: ['manage warehouse'] },
    },
    {
      path: 'warehouses/edit/:id(\\d+)',
      component: () => import('../views/warehouse/components/WarehouseEdit'),
      name: 'EditWarehouse',
      meta: { title: 'EditWarehouse', noCache: true, permissions: ['manage warehouse'] },
      hidden: true,
    },
    // warehouse inventory routes
    {
      path: 'warehouse-inventory/list',
      component: () => import('../views/warehouse-inventory/List'),
      name: 'warehouseInventoryList',
      meta: { title: 'warehouseInventoryList', icon: 'Memo', permissions: ['manage warehouse inventory'] },
    },
    {
      path: 'warehouse-inventory/pending-list',
      component: () => import('../views/warehouse-inventory/PendingList'),
      name: 'warehouseInventoryPendingList',
      meta: { title: 'warehouseInventoryPendingList', icon: 'Memo', permissions: ['manage warehouse inventory'] },
      hidden: true,
    },
    {
      path: 'receive-product/:id(\\d+)',
      component: () => import('../views/warehouse-inventory/ReceiveProduct'),
      name: 'warehouseInventoryReceiveProduct',
      meta: { title: 'warehouseInventoryReceiveProduct', icon: 'Memo', permissions: ['manage warehouse inventory'] },
      hidden: true,
    },
    // project inventory routes
    {
      path: 'project-inventory/list',
      component: () => import('../views/project-inventory/List'),
      name: 'projectInventoryList',
      meta: { title: 'projectInventoryList', icon: 'Memo', permissions: ['manage warehouse inventory'] },
    },

    // stock routes
    {
      path: 'stock/list',
      component: () => import('../views/stock/List'),
      name: 'stockList',
      meta: { title: 'stockList', icon: 'Memo', permissions: ['manage warehouse inventory'] },
    },

    // Asset routes
    {
      path: 'asset/list',
      component: () => import('../views/asset/List'),
      name: 'assetList',
      meta: { title: 'assetList', icon: 'Memo', permissions: ['view consumption list'] },
    },
    {
      path: 'asset/create',
      component: () => import ('../views/asset/Create'),
      name: 'assetCreate',
      meta: { title: 'assetCreate', icon: 'Memo', permissions: ['add consumption']  },
      hidden: true,
    },    {
      path: 'asset/edit/:id',
      component: () => import('../views/asset/Edit'),
      name: 'assetEdit',
      meta: { title: 'assetEdit', noCache: true, permissions: ['view consumption'] },
      hidden: true,
    },
    {
      path: 'asset/view/:id',
      component: () => import ('../views/asset/View'),
      name: 'assetView',
      meta: { title: 'assetView', icon: 'Memo', permissions: ['view consumption']  },
      hidden: true,
    },
    // Transfer routes
    {
      path: 'transfer/list',
      component: () => import('../views/transfer/List'),
      name: 'transferList',
      meta: { title: 'transferList', icon: 'Memo', permissions: ['view transfer list'] },
    },
    {
      path: 'transfers/Create',
      component: () => import ('../views/transfer/Create'),
      name: 'transferCreate',
      meta: { title: 'transferCreate', icon: 'Memo', permissions: ['add transfer']  },
      hidden: true,
    },
    {
      path: 'transfers/view/:id(\\d+)',
      component: () => import ('../views/transfer/View'),
      name: 'transferView',
      meta: { title: 'transferView', icon: 'Memo', permissions: ['view transfer']  },
      hidden: true,
    },

    // Delivery routes
    {
      path: 'delivery/list',
      component: () => import('../views/delivery/List'),
      name: 'deliveryList',
      meta: { title: 'deliveryList', icon: 'Memo', permissions: ['view delivery list'] },
    },
    {
      path: 'deliveries/Create',
      component: () => import ('../views/delivery/Create'),
      name: 'deliveryCreate',
      meta: { title: 'deliveryCreate', icon: 'Memo', permissions: ['add delivery']  },
      hidden: true,
    },
    {
      path: 'deliveries/view/:id',
      component: () => import ('../views/delivery/View'),
      name: 'deliveryView',
      meta: { title: 'deliveryView', icon: 'Memo', permissions: ['view delivery']  },
      hidden: true,
    },

    // Wastage routes
    {
        path: 'wastage/list',
        component: () => import('../views/wastage/List'),
        name: 'wastageList',
        meta: { title: 'wastageList', icon: 'Memo', permissions: ['view wastage list'] },
    },
    {
        path: 'wastages/Create',
        component: () => import ('../views/wastage/Create'),
        name: 'wastageCreate',
        meta: { title: 'wastageCreate', icon: 'Memo', permissions: ['add wastage']  },
        hidden: true,
    },
    {
        path: 'wastages/view/:id(\\d+)',
        component: () => import ('../views/wastage/View'),
        name: 'wastageView',
        meta: { title: 'wastageView', icon: 'Memo', permissions: ['view wastage']  },
        hidden: true,
    },
    {
      path: 'wastages/check/:id',
      component: () => import ('../views/wastage/Edit'),
      name: 'wastageCheck',
      meta: { title: 'wastageCheck', icon: 'Memo', permissions: ['check wastage']  },
      hidden: true,
    },
    {
      path: 'wastages/approve/:id',
      component: () => import ('../views/wastage/Edit'),
      name: 'wastageApprove',
      meta: { title: 'wastageApprove', icon: 'Memo', permissions: ['approve wastage']  },
      hidden: true,
    },

    // Consumption routes
    {
        path: 'consumption/list',
        component: () => import('../views/consumption/List'),
        name: 'consumptionList',
        meta: { title: 'consumptionList', icon: 'Memo', permissions: ['view consumption list'] },
    },
    {
        path: 'consumptions/Create',
        component: () => import ('../views/consumption/Create'),
        name: 'consumptionCreate',
        meta: { title: 'consumptionCreate', icon: 'Memo', permissions: ['add consumption']  },
        hidden: true,
    },
    {
        path: 'consumptions/view/:id(\\d+)',
        component: () => import ('../views/consumption/View'),
        name: 'consumptionView',
        meta: { title: 'consumptionView', icon: 'Memo', permissions: ['view consumption']  },
        hidden: true,
    },
    {
      path: 'consumptions/check/:id',
      component: () => import ('../views/consumption/Edit'),
      name: 'consumptionCheck',
      meta: { title: 'consumptionCheck', icon: 'Memo', permissions: ['check consumption']  },
      hidden: true,
    },
    {
      path: 'consumptions/approve/:id',
      component: () => import ('../views/consumption/Edit'),
      name: 'consumptionApprove',
      meta: { title: 'consumptionApprove', icon: 'Memo', permissions: ['approve consumption']  },
      hidden: true,
    },

    // Quotation routes
    {
      path: 'quotation/list',
      component: () => import('../views/quotation/List'),
      name: 'quotationList',
      meta: { title: 'quotationList', icon: 'Memo', permissions: ['view quotation list'] },
    },
    {
      path: 'quotation/create',
      component: () => import('../views/quotation/Create'),
      name: 'quotationEdit',
      meta: { title: 'quotationEdit', icon: 'Memo', permissions: ['add quotation'] },
      hidden: true,
    },
    {
      path: 'quotation/view/:id(\\d+)',
      component: () => import('../views/quotation/View'),
      name: 'quotationView',
      meta: { title: 'quotationEdit', noCache: true, permissions: ['view quotation'] },
      hidden: true,
    },
    // client-price-list routes
    {
      path: 'client-price-list/list',
      component: () => import('../views/client-price-list/List'),
      name: 'clientPriceList',
      meta: {
        title: 'clientPriceList',
        icon: 'Memo',
        permissions: ['view client price list'],
      },
    },
    {
      path: 'client-price-list/create',
      component: () => import('../views/client-price-list/Create'),
      name: 'clientPriceCreate',
      meta: {
        title: 'clientPriceCreate',
        icon: 'edit',
        permissions: ['add client price list'],
      },
      hidden: true,
    },
    {
      path: 'client-price-list/view/:id(\\d+)',
      component: () => import('../views/client-price-list/View'),
      name: 'clientPriceEdit',
      meta: {
        title: 'clientPriceEdit',
        noCache: true,
        permissions: ['update client price list'],
      },
      hidden: true,
    },

    // subcontractor-price-list routes
    {
      path: 'subcontractor-price-list/list',
      component: () => import('../views/subcontractor-price-list/List'),
      name: 'subcontractorPriceList',
      meta: {
        title: 'subcontractorPriceList',
        icon: 'Memo',
        permissions: ['view subcontractor price list'],
      },
    },
    {
      path: 'subcontractor-price-list/create',
      component: () => import('../views/subcontractor-price-list/Create'),
      name: 'subcontractorPriceCreate',
      meta: {
        title: 'subcontractorPriceCreate',
        icon: 'edit',
        permissions: ['add subcontractor price list'],
      },
      hidden: true,
    },
    {
      path: 'subcontractor-price-list/view/:id(\\d+)',
      component: () => import('../views/subcontractor-price-list/View'),
      name: 'subcontractorPriceEdit',
      meta: {
        title: 'subcontractorPriceEdit',
        noCache: true,
        permissions: ['update subcontractor price list'],
      },
      hidden: true,
    },
    {
      path: 'reports',
      component: () => import('../views/report/Index'),
      name: 'inventoryReport',
      meta: { title: 'Reports', icon: 'Memo', permissions: ['view reports'] },
    },

    {
      path: 'reports/summary',
      component: () => import('../views/report/Summary'),
      name: 'summaryReport',
      meta: { title: 'Summery Reports', icon: 'report', permissions: ['view reports'] },
      hidden: true,
    },

  ],
};

export default inventoryRoutes;
